import React, { useState } from 'react';
import { FormattedIcon } from '@components/icons';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';

// styles
import { Container, NormalText } from '@styles';
import {
  FAQSection,
  AccordionButton,
  AccodionText,
  AccordionContent,
  StyledContainer,
  Row,
  LeftCol,
  RightCol,
  Title,
} from './styles';

const Accordion = ({ data, id, expanded, setExpanded }) => {
  const isOpen = id === expanded;

  return (
    <StyledContainer>
      <AccordionButton
        isOpen={isOpen}
        onClick={() => setExpanded(isOpen ? false : id)}
      >
        <FormattedIcon name="chevron" />
        <AccodionText>{data.question}</AccodionText>
      </AccordionButton>
      <AccordionContent isOpen={isOpen}>
        <RichText render={data.answer} Component={NormalText} />
      </AccordionContent>
    </StyledContainer>
  );
};

const FAQ = ({ data }) => {
  const [expanded, setExpanded] = useState(0);

  return (
    <FAQSection>
      <Container normal>
        <Row>
          <LeftCol>
            <Title>Frequently asked questions</Title>
            <NormalText>
              Consider checking this page for answers before contacting us. If
              you still have any questions, feel free to write us an email{' '}
              <Link to="/contact">here</Link>.
            </NormalText>
          </LeftCol>
          <RightCol>
            {data.map((reference, i) => (
              <Accordion
                key={i}
                data={reference}
                id={i}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            ))}
          </RightCol>
        </Row>
      </Container>
    </FAQSection>
  );
};

export default FAQ;
