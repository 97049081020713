import React from 'react';
import BreadCrumb from '@components/breadcrumb';
import SEO from '@components/seo';
import FAQ from '@views/faq';
import { graphql } from 'gatsby';

const RenderBody = ({ doc }) => {
  const faq = doc.faq;

  return (
    <>
      <SEO
        title="FAQ"
        uri="faq"
        desc="Before asking us, see if your question has already been answered."
      />
      <BreadCrumb name="FAQ" />
      <FAQ data={faq} />
    </>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allFaqs.edges.slice(0, 1).pop();

  if (!doc) return null;
  return <RenderBody doc={doc.node} />;
};

export const query = graphql`
  {
    prismic {
      allFaqs {
        edges {
          node {
            faq {
              question
              answer
            }
          }
        }
      }
    }
  }
`;
