import styled from 'styled-components';
import { theme, media } from '@styles';

const { colors } = theme;

export const FAQSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3rem 0;
`;

export const StyledContainer = styled.div`
  :not(:first-child) {
    margin-top: 1.7rem;
  }
`;

export const AccordionIcon = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 8px;
  span {
    width: 16px;
    height: 4px;
    background: ${colors.text};
    transition: all 0.1s ease-in-out;
  }
`;

export const AccordionButton = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.main};
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0 1em;

  svg {
    width: 1em;
    color: ${colors.text};
    opacity: 0.8;
    ${(props) => props.isOpen && `transform: rotate(180deg);`};
  }
`;

export const AccodionText = styled.h5`
  font-size: 14px;
  color: ${colors.text};
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.3;
  margin: -2px;
  padding: 22px 50px 21px 40px;
  text-transform: uppercase;
`;

export const AccordionContent = styled.div`
  overflow: hidden;
  padding: 1.25rem;
  background-color: ${colors.hollow};
  display: ${(props) => (props.isOpen ? 'relative' : 'none')};
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

const BaseCol = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const LeftCol = styled(BaseCol)`
  flex: 0 0 50%;
  max-width: 50%;

  ${media.desktop`
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  `};
`;
export const RightCol = styled(BaseCol)`
  flex: 0 0 50%;
  max-width: 50%;

  ${media.desktop`
    flex: 0 0 100%;
    max-width: 100%;
  `};
`;

export const Title = styled.h3`
  color: ${colors.text};
  font-size: 40px;
  line-height: 1;
  margin-bottom: 0.65em;
`;
